import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet"
import Hero from "~sections/project/EmailAutomation/Hero/Hero"
import Features from "~sections/project/EmailAutomation/Features/Features"
import Boost from "~sections/project/EmailAutomation/Boost/Boost"
import Build from "~sections/project/EmailAutomation/Build/Build"
import Personalization from "~sections/project/EmailAutomation/Personalization/AutomationSec"
import Sequences from "~sections/project/EmailAutomation/Sequences/Sequences"
import RealTime from "~sections/project/EmailAutomation/RealTime/RealTime"
import StreamLine from "~sections/project/EmailAutomation/StreamLine/Streamline"

const header = {
    headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
    containerFluid:false,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="Login"
        btnTwoText="14 day free trial"
        mr="15px"
        mrLG="0"
      />
    ),
  }

  export default function EmailAutomation() {
    return (
      <>
      <Helmet>
    <title>Automate your emails with the click of a button</title>
    <meta name="description" content="Guide social is a video-based marketing agency that helps clients reach more people using We-Connect. Watch our interview with founder Matt Johnston to learn more."/>
    <meta property="og:title" content="We-Connect | Automate your emails with the click of a button"/>
    <meta property="og:description" content="Double your response rate with email automation and LinkedIn outreach. Never let another lead slip through the cracks with our automated follow-ups."/>
    <meta property="og:image" content="https://we-connect.io/automate-your-emails-with-we-connect.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/email-automation"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="We-Connect | Automate your emails with the click of a button "/>
    <meta property="twitter:description" content="Double your response rate with email automation and LinkedIn outreach. Never let another lead slip through the cracks with our automated follow-ups."/>
    <meta property="twitter:image" content="https://we-connect.io/automate-your-emails-with-we-connect.png"/>
    </Helmet>
      <PageWrapper headerConfig={header}>
        <Hero />
        <Boost />
        <Build />
        <Personalization />
        <Sequences />
        <Features />
        <RealTime />
        <StreamLine />
        <Testimonial />
        <CtaSection />
        <FooterOne />
      </PageWrapper>
      </>
    )
  }
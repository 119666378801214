import React from 'react'
import Content from '../Features/style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/EmailAutomation/SVG/Boost-Email-Deliverability.svg";

export default function Boost({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Boost Email Deliverability"/> </Content.Title>

          <Content.Text> <SuperTag value="Ensure your email lands in your prospect's inbox and not in the spam folder by using We-Connect. We’ll help you increase your email deliverability to double your reply rate."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Get Started for Free</Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}
import React from 'react'
import Hero from './style'
import { Col, Row, Container  } from 'react-bootstrap'
import { SuperTag } from '~components'
import img from '~assets/image/media/EmailAutomation/SVG/Double-Your-Conversion-with-Email-Automation.svg'
import { Link } from 'gatsby'
export default function HeroSection({backgroundColor,colClasses,...rest}){
return(
<Hero {...rest}>
  <Container>
    <Row className="row justify-content-center text-center">
      <Col className={colClasses ? colClasses : "col-xl-8"}>
        <Hero.Title as="h1">Double Your Conversions <br className="d-none d-xs-none d-lg-none d-xl-block" /> with Email Automation</Hero.Title>
        <p>Convert more leads when you combine LinkedIn outreach with cold email automation.</p>
        <Hero.Image>
        <img className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />
        </Hero.Image>
        <p>Scale your email outreach and maximize results.</p>
        <Link target="_blank"  to="//account.we-connect.io/signup"><Hero.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} rounded={true} sizeX="180px" sizeY="56px">Try it now</Hero.Button></Link>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}